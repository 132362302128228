<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="(item,index) in list" :key="item.coupon_id" class="list-item" @click="onChecked(index)">
        <div class="list-item-hd">
          <div class="list-item-hd-money">￥<span class="fz24">{{ item.coupon_dis_amount }}</span></div>
          <div class="list-item-hd-moneyHreshold">满{{ item.coupon_req_amount }}可用</div>
        </div>
        <div class="list-item-bd">
          <div class="list-item-bd-inner">
            <div class="list-item-bd-title">{{ item.coupon_title }}</div>
            <div class="list-item-bd-txt">{{ item.coupon_start_time }} - {{ item.coupon_end_time }}</div>
          </div>
          <div class="list-item-bd-actions">
            <div class="list-item-radio" :class="{'list-item-radio--checked':index === active}">
              <van-icon name="success" />
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="fixed-bottom-bar">
      <van-button plain type="default" block @click="redirect">不使用优惠</van-button>
    </div>
  </div>
</template>
<script>
import { Radio } from 'vant'
import PageMixin from '@/mixins/page'
import storage from 'good-storage'

export default {
  name: 'UsableCoupon',
  components: {
    [Radio.name]: Radio
  },
  mixins: [PageMixin],
  data() {
    return {
      active: -1, // 当前选中优惠券
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    // this.getIndexList()
  },
  methods: {
    onListLoad() {
      this.$api.coupon_index({ 'per-page': this.pageSize, page: this.pageCurrent, coupon_status: 1 }).then(res => {
        res.items.forEach(item => {
          item.checked = false
        })
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '无可用优惠券~'
        this.pageCurrent += 1
      })
    },
    onChecked(index) {
      this.active = index
      storage.session.set('choseCoupon', this.list[index])
      this.$toast.loading({
        message: '',
        loadingType: 'spinner'
      })
      setTimeout(() => {
        this.$toast.clear()
        this.$router.back()
      }, 300)
    },
    redirect() {
      storage.session.remove('choseCoupon')
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  padding-top: 20px;
}
.list-item {
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(228, 228, 228, 0.5);
  &-hd {
    box-sizing: border-box;
    width: 100px;
    text-align: center;
    height: 100%;
    padding: 20px 0;
    background: linear-gradient(
      309deg,
      rgba(255, 112, 61, 1) 0%,
      rgba(255, 70, 57, 1) 100%
    );
    font-size: 14px;
    color: #fff;
    &-money {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  &-bd {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    &-title {
      font-size: 16px;
      color: #333;
      margin-bottom: 5px;
      max-width: 120px;
      overflow: hidden;

    }
    &-txt {
      font-size: 12px;
      color: #999;
    }
    &-actions {
      font-size: 12px;
    }
  }
}
.list-item-radio{
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  color: transparent;
  font-size: 18px;
  line-height: inherit;
  text-align: center;
  border: 1px solid #c8c9cc;
  border-radius:50%;
  transition-duration: 0.2s;
  transition-property: color, border-color, background-color;
  &--checked{
    color: #fff;
    background-color: #00de93;
    border-color: #00de93;
  }
}
.fixed-bottom-bar{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 16px;
  background-color: #fff;
}
</style>
